import * as Yup from 'yup'

export const getSignInSchema = () => {
  return Yup.object().shape({
    // role: Yup.string().trim().required("Role is required"),
    email: Yup.string()
      .trim()
      .required('Enter email address')
      .email('Invalid email'),
    password: Yup.string().trim().required('Please enter password'),
    // .matches(/^[a-z0-9]+$/i, "Password Must Contain Letters and Digits"),
  })
}

export const getSignUpSchema = () => () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .required('Full name is required')
      .matches(
        /^[a-z]{2,24}\s[a-z]{2,24}$/gi,
        'Enter correct your full name. Ex: John Doe',
      )
      .min(5, 'minimum 5 characters allowed')
      .max(50, 'maximum 50 characters allowed'),
    email: Yup.string()
      .trim()
      .required('Enter email address')
      .email('Invalid email'),
    password: Yup.string()
      .trim()
      .required('Please enter password')
      .matches(/^[a-z0-9]+$/i, 'Password Must Contain Letters and Digits')
      .min(6, 'minimum 6 characters allowed')
      .max(15, 'maximum 15 characters allowed'),
  })
}

export const getGeneralInfoFormSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .required('Full Name is required')
      .matches(
        // /^([a-z-]){2,25}$/i,
        /^[a-z]{2,24}\s[a-z]{2,24}$/gi,
        'Enter correct your full name. Ex: John Doe',
      )
      .min(5, 'minimum 5 characters allowed')
      .max(50, 'maximum 50 characters allowed'),
    // email: Yup.string()
    //   .trim()
    //   .required("Email is required")
    //   .email("Enter valid email"),
  })
}

export const getChangePassFormSchema = () => {
  return Yup.object().shape({
    // current_password: Yup.string()
    //   .trim()
    //   .required("Current password is required"),
    password: Yup.string().trim().required('Please enter password'),
    // .matches(/^[a-z0-9]+$/i, "")
    // .min(8, "")
    // .max(8, ""),
    confirm_new_password: Yup.string()
      .trim()
      .required('New password is required')
      .oneOf([Yup.ref('password'), null], 'Password are not matching'),
  })
}

export const getIdentifyFormSchema = () => ({
  email: Yup.string().trim().required('').email(''),
})

export const getPackTitleSchema = (title = 'Title') => {
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(`${title} is required`)
      .min(2, 'minimum 2 characters allowed')
      .max(99, 'maximum 99 characters allowed'),
  })
}
export const getTestTitleSchema = (title = 'Title') => {
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(`${title} is required`)
      .min(2, 'minimum 2 characters allowed')
      .max(300, 'maximum 300 characters allowed'),
  })
}

export const getTestQuestionSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(`Question is required`)
      .min(2, 'minimum 2 characters allowed')
      .max(500, 'maximum 500 characters allowed'),
    answers: Yup.array().of(
      Yup.object().shape({
        text: Yup.string()
          .trim()
          .required(`Answer is required`)
          .max(500, 'maximum 500 characters allowed'),
        isCorrect: Yup.boolean().test({
          message: 'At least one option must be correct',
          test: (values, { from }) => {
            const { answers } = from[1].value
            return answers.some(({ isCorrect }) => isCorrect)
          },
          
        }),
      }),
    ),
    // .test(
    //   "at-least-one-correct",
    //   "At least one option must be correct",
    //   (values) => values.some(({ isCorrect }) => isCorrect)
    // ),
  })
}

export const getVideoPageSchema = (isCreationStatus) => {
  const conditionalFields = isCreationStatus
    ? {
      file_data: Yup.mixed().required('Video is required').test({
        message: 'Video supported only this format: mp4, avi',
        test: (value) => {
          const SUPPORTED_FORMATS = ['video/mp4', 'video/avi']
          if (value && typeof value === 'object') {
            return SUPPORTED_FORMATS.includes(value.type)
          }
          return true
        },
      }).test({
        message: 'Video is smaller than 10MB',
        test: (value) => {
          if (value?.size) return value.size > 10485760
          return true
        },
      }).test({
        message: 'Video is larger than 5GB',
        test: (value) => {
          if (value?.size) return value.size < 5368709120
          return true
        },
      }),
    }
    : {}
  
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required('Title is required')
      .min(5, 'minimum 5 characters allowed')
      .max(99, 'maximum 99 characters allowed'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .max(1000, 'maximum 1000 characters allowed'),
    // .min(20, "minimum 20 characters allowed")
    // attachments: Yup.string().trim().max(255, "maximum 255 characters allowed"),
    attachments: Yup.array().of(
      Yup.object().shape({
        link: Yup.string()
          .trim()
          .required(`Url is required`)
          .max(255, 'maximum 255 characters allowed'),
        linkLabel: Yup.string()
          .trim()
          .required(`Link name is required`)
          .max(100, 'maximum 100 characters allowed'),
      }),
    ),
    ...conditionalFields,
  })
}

export const getPackSettingsSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(`Title is required`)
      .min(2, 'minimum 2 characters allowed')
      .max(99, 'maximum 99 characters allowed'),
    type: Yup.string().required(`Type is required`),
    landingLink: Yup.string().trim().max(150, 'maximum 150 characters allowed'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .min(20, 'minimum 20 characters allowed')
      .max(300, 'maximum 300 characters allowed'),
    price: Yup.string().required(`Price is required`),
    certificateLabel: Yup.string()
      .trim()
      .max(100, 'maximum 100 characters allowed'),
  })
}

export const getGenerateLinkFormSchema = (isNewStudent) => {
  const conditionalFields = isNewStudent
    ? {
      fullName: Yup.string()
        .trim()
        .required('Full name is required')
        .matches(
          /^[a-z]{2,24}\s[a-z]{2,24}$/gi,
          'Enter correct your full name. Ex: John Doe',
        )
        .min(5, 'minimum 5 characters allowed')
        .max(50, 'maximum 50 characters allowed'),
      email: Yup.string()
        .trim()
        .required('Enter email address')
        .email('Invalid email'),
      phone: Yup.string().required(`Phone is required`),
    }
    : {}
  
  return Yup.object().shape({
    ...conditionalFields,
    packId: Yup.string().trim().required('Course is required'),
  })
}

export const getSaveSalesAccSchema = (isNewSales) => {
  let fullName = Yup.string()
    .trim()
    .matches(
      /^[a-z]{2,24}\s[a-z]{2,24}$/gi,
      'Enter correct your full name. Ex: John Doe',
    )
    .min(5, 'minimum 5 characters allowed')
    .max(50, 'maximum 50 characters allowed')
  
  let password = Yup.string()
    .trim()
    .matches(/^[a-z0-9]+$/i, 'Password Must Contain Letters and Digits')
    .min(6, 'minimum 6 characters allowed')
    .max(15, 'maximum 15 characters allowed')
  let email = Yup.string().trim().email('Invalid email')
  
  if (isNewSales) {
    fullName = fullName.required('Full name is required')
    password = password.required('Please enter password')
    email = email.required('Enter email address')
  }
  
  return Yup.object().shape({
    ...{ fullName, password, email },
    ...{
      confirmPassword: Yup.string().trim().test({
        message: 'Passwords must match',
        test: function (value) {
          return this.parent.password === value
        },
      }),
    },
  })
}

// const getMaskValidation = (length, errMessage) =>
//   Yup.string().test("len", errMessage, (val) => {
//     return val ? val.replace(/[^0-9.]/g, "").length === length : true;
//   });
// const BankDetailsValidationSchema = Yup.object().shape({
//   credit_card: getMaskValidation(16, "invalid card number"),
//   credit_card_name: Yup.string().matches(
//     /^[a-z]{2,20} [a-z]{2,20}/gi,
//     "Enter your full name"
//   ),
// });
