import { useField } from 'formik'

import classes from './Style.module.scss'

const CustomCheckbox = ({ className = '', ...restProps }) => {
  return (
    <input
      className={`${classes['custom-checkbox']}${
        className ? ` ${className}` : ''
      }`}
      type="checkbox"
      {...restProps}
    />
  )
}

export const CheckboxValid = ({ name, ...restProps }) => {
  const [fields, meta] = useField(name)
  
  return (
    <CustomCheckbox
      className={meta.touched && meta.error ? classes.error : ''}
      {...fields}
      {...restProps}
    />
  )
}

export default CustomCheckbox
